/* exemple avec les heures :
                   avec isCompact
0    ""            ""
1    "1 heure"     "1 h"
2    "2 heures"    "2 h"
*/
function humanizeNumber(n: number, isCompact: boolean, compactUnit: string, singularUnit: string, plurialUnit: string) {
  if (n === 0) return ""
  const unit: string = isCompact ? compactUnit : n === 1 ? singularUnit : plurialUnit
  return `${n} ${unit}`
}

/*                              avec isCompact
0..59     "0"                   "0"
60..119   "1 minute"            "1 m"
120..179  "2 minutes"           "2 m"
3599      "59 minutes"          "59 m"
3600      "1 heure"             "1 h"
3660      "1 heure 1 minute"    "1 h 1 m"
7200      "2 heures"            "2 h"
*/
export function humanizeDuration(seconds: number, isCompact: boolean = false) {
  if (seconds < 60) return "0"
  const parts: string[] = []
  const h = humanizeNumber(Math.floor(seconds / 3600), isCompact, "h", "heure", "heures")
  const m = humanizeNumber(Math.floor((seconds % 3600) / 60), isCompact, "m", "minute", "minutes")
  h && parts.push(h)
  m && parts.push(m)
  return parts.join(" ")
}