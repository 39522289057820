export const fr = {
  resources: {
    account: {
      name: 'Utilisateur |||| Utilisateurs',
      actions: {
        block: 'Bloquer',
        downgrade: 'Rétrograder en Utilisateur',
        promote: 'Promouvoir en Annonceur',
        send: 'Contacter',
        unlock: 'Déverouiller'
      },
      contact: {
        body: 'Message',
        subject: 'Sujet'
      },
      fields: {
        city: 'Ville',
        creationDate: 'Date d’inscription',
        email: 'Email',
        firstName: 'Prénom',
        image: {
          filename: 'Avatar'
        },
        isBlocked: 'Actif ?',
        lastName: 'Nom',
        notifiable: 'Accepte les notifications ?',
        notifChallengeNew: 'Nouveaux Challenges',
        notifChallengeEndSoon: 'Challenge se terminant bientôt',
        notifSoloNotLeading: 'Si plus en tête du classement (solo)',
        notifTeamNotLeading: 'Si plus en tête du classement (équipe)',
        notifTeamWon: 'Si l’équipe a gagné',
        notifFromQueeny: 'De la part de Queeny',
        notifFromPartners: 'De la part des partenaires',
        phone: 'Téléphone',
        postalCode: 'Code postal',
        pseudo: 'Raison sociale ou pseudo',
        updateDate: 'Date màj'
      },
      filters: {
        admin: 'Administrateur',
        partner: 'Annonceur',
        user: 'Utilisateur'
      },
      helpers: {
        adminSent: 'Votre message a bien été envoyé',
        challenges: 'Affiche l’ensemble des challenges commencé par l’utilisateur',
        devices: 'Affiche l’ensemble des smartphones utilisés par l’utilisateur',
        locked: 'Ce compte utilisateur a été bloqué',
        sessions: 'Affiche l’ensemble des sessions que l’utilisateur a effectué',
        unlocked: 'Ce compte utilisateur a été déverouillé',
        emailValid: {
          ok: 'Email confirmé',
          ko: 'Compte non confirmé'
        },
        isBlocked: 'Compte bloqué'
      },
      tabs: {
        challenges: 'Challenges participés',
        intro: 'Informations générales',
        partner: 'Challenges partenaires',
        sessions: 'Liste des sessions'
      }
    },
    admin: {
      menu: 'Menu admin'
    },
    audit: {
      name: 'Audit |||| Audits',
      filters: {
        admin_create_challenge: 'Admin a créé un Challenge',
        admin_delete_challenge: 'Admin a supprimé un Challenge',
        admin_unauthorized_access_to_resource: 'Accès interdit : ressource de niveau Admin',
        admin_update_challenge: 'Admin a modifié un Challenge',
        admin_validate_challenge: 'Admin a validé un Challenge',
        challenge_won: 'Challenge gagné',
        partner_challenge_create: 'Annonceur a créé un Challenge',
        partner_challenge_update: 'Annonceur a modifié un Challenge',
        partner_new_order: 'Annonceur a créé une nouvelle commande',
        partner_unauthorized_access_to_resource: 'Accès interdit : ressource de niveau Annonceur',
        partner_register: 'Annonceur inscrit',
        session_start_failed_no_account: 'Session ne peut démarrer : pas de compte',
        session_start_failed_no_challenge: 'Session ne peut démarrer : pas de Challenge',
        session_start_ko: 'Session ne peut démarrer',
        session_start_ok: 'Session démarrée',
        session_stop: 'Session terminée',
        session_suspicious: 'Session suspecte',
        user_blocked_tried_to_login: 'Utilisateur bloqué essaie de se connecter',
        user_delete_account: 'Utilisateur a supprimé son compte',
        user_failed_to_login: 'Utilisateur n’a pu se connecter',
        user_login: 'Utilisateur connecté',
        user_logout: 'Utilisateur déconnecté',
        user_open_app: 'Utilisateur a ouvert l’App',
        user_refreshing_tokens: 'Utilisateur rafraichit ses tokens',
        user_register: 'Utilisateur inscrit',
        user_request_new_password: 'Utilisateur veut réinitialiser son mot de passe',
        user_request_password_changed: 'Utilisateur a complété la réinitialisation du mot de passe',
        user_start_session: 'Utilisateur démarre une session',
        user_unlocked_challenge: 'Utilisateur a débloqué un Challenge',
        user_update_profile: 'Utilisateur met à jour son profil'
      },
      fields: {
        creationDate: 'Date de création',
        entityId: 'Entité'
      }
    },
    auth: {
      form: {
        requestnew: 'Demande envoyée ! Veuillez vérifier votre boîte mail',
        reset: 'Votre mot de passe a été modifié !',
        confirmed: 'Votre adresse email a été confirmée !',
        error: 'Erreur : impossible d’envoyer votre demande',
        errors: {
          password_recover_invalid_token: 'Votre demande de réinitialisation est invalide',
          password_recover_token_expired: 'Votre demande de réinitialisation a expirée',
          confirm_account_invalid_token: 'Désolé, ce lien de confirmation est invalide'
        }
      },
      helpers: {
        welcome: 'Bienvenue dans votre tableau de bord personnalisé.'
      }
    },
    challenge: {
      name: 'Challenge |||| Challenges',
      title: 'Liste des Challenges',
      actions: {
        finalizeDraft: 'Finaliser le brouillon',
        ranking: 'Classement',
        sessions: 'Sessions',
        stats: 'Statistiques',
        validate: 'Valider'
      },
      criterias: {
        calories: 'Calories',
        distance: 'Distance',
        duration: 'Durée'
      },
      fields: {
        defaultNotify: 'Conserver la planification par défaut',
        description: 'Description',
        endDate: 'Date de fin',
        generatePrizes: 'Activer la génération',
        id: 'N°',
        maxTeamSize: 'Nombre de personnes maximum par équipe',
        name: 'Nom',
        objective: 'Objectif',
        onlyAdminCanCreateTeams: 'Seul l’admin peut créer les équipes',
        priority: 'Priorité',
        prizes: 'Lots & récompenses (image)',
        pdfPrizes: 'Lots & récompenses (PDF)',
        progress: 'Progression de l’objectif global',
        // qrCodeCardCredit: 'Montant en € par carte virtuelle',
        qrCodeCardCredit: 'Crédit par carte virtuelle',
        qrCodeGroups: 'Groupes machines',
        qrCodePoolSize: 'Nombre de QR codes à générer',
        scope: 'Visibilité',
        sentDate: 'Date d’envoi de la notification',
        sessionDuration: 'Durée de la session (minutes)',
        solo: 'En solo',
        startDate: 'Date de début',
        status: 'Statut',
        onGoing: 'En cours ?',
        team: 'En équipe',
        teams: 'Équipes'
      },
      form: {
        created: 'Le Challenge a été créé',
        edited: 'Le Challenge a été modifié'
      },
      helpers: {
        created: 'Votre Challenge a été créé avec succès !',
        finalizeDraft: 'Le Challenge a été envoyé pour validation.',
        generatePrizes:
          'Cochez la case pour activer la génération de QR codes compatibles NAYAX. En cochant cette case, vous ne pourrez pas ajouter de lots à la main.',
        imageDeleted: 'Bannière supprimée.',
        imageNotDeleted: 'Impossible de supprimer l’image. Un Challenge doit avoir au moins 1 image.',
        notification:
          'Par défaut, une notification est envoyée à tout le monde le matin à 8h quand vous créez un Challenge. Vous pouvez décocher cette case et mettre la date et l’heure que vous voulez.',
        prizesArePdf: 'Les récompenses sont envoyées au format PDF.',
        url: 'Format https:// uniquement.',
        validated: 'Le Challenge a été validé !'
      },
      labels: {
        criteria: 'Critère pour remporter le Challenge',
        global: 'Challenge global pour tous les participants ?',
        images: 'Images du Challenge',
        partner: 'Email du responsable',
        prizes: 'Image ou PDF',
        qrCodeIsMoney: 'Unité',
        type: 'Type de Challenge',
        url: 'URL du partenaire'
      },
      onGoing: {
        ended: 'Terminé',
        inProgress: 'En cours',
        onGoing: 'À venir'
      },
      qrCodeIsMoney: {
        true: '€',
        false: 'transactions'
      },
      scope: {
        private: 'Privé',
        public: 'Public'
      },
      status: {
        active: 'Actif',
        closed: 'Fermé',
        deleted: 'Supprimé',
        draft: 'Brouillon',
        toValidate: 'À valider'
      },
      tabs: {
        images: 'Bannières',
        intro: 'Informations générales',
        notification: 'Notification',
        prizes: 'Lots',
        teams: 'Équipes'
      },
      type: {
        solo: 'Solo',
        team: 'Equipe'
      }
    },
    contact: {
      name: 'Contact |||| Contacts',
      fields: {
        account: {
          id: 'Demande créé par'
        },
        creationDate: 'Date de création',
        processed: 'Traité',
        processedBy: {
          id: 'Message traité par'
        },
        subject: 'Sujet',
        updateDate: 'Date de mise à jour'
      },
      subject: {
        bug: 'J’ai rencontré un bug',
        evolution: 'J’ai une suggestion',
        reward: 'Je n’ai jamais reçu ma récompense',
        other: 'Autre sujet',
        love: 'Je vous kiffe ❤️'
      },
      actions: {
        done: 'Marqué comme Traité'
      }
    },
    'csc-device': {
      name: 'Périphérique CSC |||| Périphériques CSC',
      fields: {
        batteryLevel: 'Batterie',
        c1: 'C1 (tours de roue)',
        c2: 'C2 (pédalier)',
        creationDate: 'Date de création',
        fullName: 'Fabricant & nom',
        name: 'Nom',
        macAddress: 'Adresse MAC',
        manufacturerName: 'Fabricant',
        updateDate: 'Date de mise à jour',
        uuid: 'UUID'
      }
    },
    device: {
      name: 'Téléphone |||| Téléphones',
      fields: {
        account: {
          email: 'Email',
          pseudo: 'Pseudo'
        },
        brand: 'Marque',
        creationDate: 'Date de création',
        model: 'Modèle',
        os: 'Système',
        updateDate: 'Date de mise à jour'
      },
      helpers: {
        sessions: 'Affiche l’ensemble des sessions effectuées avec ce Smartphone'
      },
      labels: {
        owner: 'Propriétaire'
      }
    },
    errors: {
      account_create_duplicate_email: 'Cette adresse mail est déjà utilisée',
      account_create_duplicate_pseudo: 'Cette raison sociale ou ce pseudo existe déjà',
      action_not_authorized: 'Désolé, vous n’êtes pas autorisé à effectuer cette action',
      Unauthorized: 'Désolé, vous n’êtes pas autorisé à effectuer cette action',
      challenge_create_code_duplicate: 'Ce code a déjà été utilisé',
      challenge_create_criteria_value: 'La valeur de l’objectif est obligatoire',
      challenge_create_description_required: 'La description est obligatoire',
      challenge_create_email_required: 'L’email est obligatoire',
      challenge_create_enddate_invalid: 'La date de fin doit être après la date de début',
      challenge_create_images_required: 'Au moins une image est obligatoire',
      challenge_create_name_required: 'Le nom est obligatoire',
      challenge_create_prizes_required: 'Au moins une récompense est obligatoire',
      challenge_create_max_images: '5 images maximum par Challenge',
      challenge_create_startdate_invalid: 'La date de début ne peut être dans le passé',
      challenge_create_url_required: 'L’URL est obligatoire',
      challenge_update_objective_lower_than: 'L’objectif ne peut être réduit car le Challenge a déjà commencé',
      prize_cannot_delete_with_attached_team: 'Impossible de supprimer une récompense avec une équipe rattachée',
      // cas du retour du "built-in global exception filter" de nestjs
      'Internal server error': "Erreur interne du serveur, contacter l'administrateur." // psam 12/23 correctif #7
    },
    notification: {
      name: 'Notification |||| Notifications',
      fields: {
        body: 'Corps de la notification',
        createdBy: {
          id: 'Créé par'
        },
        creationDate: 'Date de création',
        immediate: 'Instantanée',
        logs: 'Réponse brute du serveur',
        recipient: 'Destinataire(s)',
        sentDate: 'Date d’envoi',
        title: 'Titre',
        topic: 'Catégorie',
        updateDate: 'Date de mise à jour'
      },
      topic: {
        challengeNew: 'Nouveau Challenge',
        challengeEndSoon: 'Challenge se termine bientôt',
        challengeRewardWon: 'Challenge gagné',
        fromQueeny: 'De la part de Queeny',
        fromPartners: 'De la part d’un partenaire'
      },
      type: {
        both: 'Les 2',
        device: 'Sur smartphone',
        email: 'Par email'
      },
      filters: {
        type: 'Type d’envoi',
        manual: 'Manuel',
        auto: 'Automatique'
      }
    },
    order: {
      name: 'Commande |||| Commandes',
      fields: {
        creationDate: 'Date de création',
      },
      labels: {
        partner: 'Client'
      }
    },
    partner: {
      name: 'Client |||| Clients',
      fields: {
        company: 'Entreprise',
        creationDate: 'Date de création',
        firstName: 'Prénom',
        lastName: 'Nom'
      },
      helpers: {
        created: 'Un nouveau partenaire a été créé avec succès !',
        downgradeFailed: 'Une erreur est survenue lors de la rétrogradation en compte Utilisateur',
        downgradeSuccess: 'L’utilisateur a été rétrogradé en compte Utilisateur',
        promoteFailed: 'Une erreur est survenue lors de la promotion en compte Annonceur',
        promoteSuccess: 'L’utilisateur a été promu en compte Annonceur'
      },
      actions: {
        definePassword: 'Finaliser mon compte',
        openterms: 'Ouvrir les CGU dans un nouvel onglet'
      }
    },
    prize: {
      name: 'Récompense |||| Récompenses',
      fields: {
        challenge: {
          name: 'Challenge',
          status: 'Challenge terminé'
        },
        creationDate: 'Date de création',
        prizeOrder: 'N° de lot',
        team: {
          status: 'Gagné ?',
          updateDate: 'Date màj'
        },
        updateDate: 'Remporté le'
      },
      labels: {
        prizes: 'Récompense',
        team: 'Équipe'
      }
    },
    rank: {
      fields: {
        id: 'Rang',
        percent: 'Progression'
      }
    },
    session: {
      name: 'Session |||| Sessions',
      fields: {
        account: {
          email: 'Email',
          pseudo: 'Pseudo'
        },
        appVersion: 'Version de l’App',
        calories: 'Calories',
        challenge: {
          name: 'Challenge'
        },
        cscDevice: 'Périphérique CSC',
        device: 'Smartphone',
        deviceEndDate: 'Fin',
        deviceStartDate: 'Début',
        distance: 'Distance',
        duration: 'Durée',
        endedBy: 'Terminée par',
        updateDate: 'Dernière mise à jour'
      },
      endedBy: {
        bluetoothLost: 'Bluetooth perdu',
        bluetooth_lost: 'Bluetooth perdu',
        force: 'Forcé',
        manual: 'Arrêt manuel',
        serverGhost: 'Session fantôme',
        server_stop_ghost_session: 'Session fantôme',
        serverTooOld: 'Session trop vieille',
        server_too_old: 'Session trop vieille',
        stop: 'Stop (inconnu)',
        suspicious: 'Session suspecte !',
        timeout: 'Utilisateur inactif',
        timeup: 'Session complète',
        time_up: 'Session complète',
        unknown: '(inconnu)',
        websocketDisconnected: 'Déconnecté',
        websocket_disconnected: 'Déconnecté',
        win: 'Gagné',
        api_send_data_win: 'Gagné'
      }
    },
    team: {
      name: 'Équipe |||| Équipes',
      fields: {
        creationDate: 'Date de création',
        firstName: 'Prénom',
        id: 'Numéro',
        lastName: 'Nom',
        name: 'Nom de l’équipe',
        challenge: {
          name: 'Nom du Challenge'
        },
        ranking: 'Classement',
        status: 'Statut',
        updateDate: 'Date màj'
      },
      helpers: {
        accounts: 'Membres de l’équipe',
        sessions: 'Liste des sessions',
        teams: 'Equipes rattachées à ce Challenge'
      }
    }
  }
}
