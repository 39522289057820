import {
  ArrayField,
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  Show,
  ShowButton,
  type ShowProps,
  SimpleShowLayout,
  TextField,
  useTranslate
} from 'react-admin'
import { FormControl, FormHelperText, Grid } from '@mui/material'

import { SessionAccountLinkField, SessionChallengeLinkField, SessionScoreField } from '../components/SessionFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import type { CscDevice } from '../types'

const CscDeviceShow = (props: ShowProps) => {
  const translate = useTranslate()
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <Grid container>
          <Grid xs={12}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.fullName')}</FormHelperText>
              <FunctionField label="Name" render={(record: CscDevice) => `${record.manufacturerName} ${record.name}`} />
            </FormControl>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.batteryLevel')}</FormHelperText>
              <FunctionField
                label="batteryLevel"
                render={(record: CscDevice) => (record.batteryLevel ? `${record.batteryLevel} %` : 'N/A')}
              />
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.creationDate')}</FormHelperText>
              <ShortDateTimeField source="creationDate" />
            </FormControl>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.updateDate')}</FormHelperText>
              <ShortDateTimeField source="updateDate" />
            </FormControl>
          </Grid>
        </Grid>
        <TextField source="macAddress" />
        <TextField source="uuid" />
        <Grid container>
          <Grid>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.c1')}</FormHelperText>
              <BooleanField source="c1" />
            </FormControl>
            <FormControl style={{ marginBottom: 8, marginRight: 32 }}>
              <FormHelperText>{translate('resources.csc-device.fields.c2')}</FormHelperText>
              <BooleanField source="c2" />
            </FormControl>
          </Grid>
        </Grid>
        <ArrayField source="sessions">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <ShortDateTimeField source="deviceStartDate" label="resources.session.fields.deviceStartDate" />
            <SessionScoreField label="Score" />
            <SessionAccountLinkField source="account.pseudo" sortable={false}
              label="resources.session.fields.account.pseudo" />
            <SessionChallengeLinkField source="challenge.name" sortable={false}
              label="resources.session.fields.challenge.name" />
            <ShowButton resource="session" label="Voir" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

export default CscDeviceShow
