import {
  Link,
  useCreatePath,
  useRecordContext
} from 'react-admin'
import type { FieldProps } from 'react-admin'
import { Collections, Done, OpenInNew, PictureAsPdf } from '@mui/icons-material'
import { Chip, IconButton, Tooltip } from '@mui/material'

import { type Prize, Status } from '../types'

// utilisé en : prize/list
const PrizeChallengeLinkField = (_props: FieldProps) => {
  const prize = useRecordContext<Prize>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('challenge', { id: prize.challenge.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{`#${data.id} - ${data.name}`}</Link>
  } else {
    return null
  }
  */
  if (!prize) return null // protection habituelle recommandée en doc
  const challenge = prize.challenge // NotNull
  return <Link to={createPath({ resource: 'challenge', type: 'show', id: challenge.id })}>{`#${challenge.id} - ${challenge.name}`}</Link>
}

// utilisé en : challenge/show, edit
const PrizeImageIcon = (_props: FieldProps) => {
  const prize = useRecordContext<Prize>()
  if (!prize) return null // protection habituelle recommandée en doc
  const isPDF = prize.image.filename.endsWith('pdf')
  return (
    <Tooltip title={isPDF ? 'Ce lot est au format PDF' : 'Ce lot est au format image'}>
      <IconButton aria-label="Collections" color="inherit">
        {isPDF ? <PictureAsPdf /> : <Collections />}
      </IconButton>
    </Tooltip>
  )
}

// utilisé en : challenge/show, edit
const PrizeImageField = (_props: FieldProps) => {
  const prize = useRecordContext<Prize>()
  if (!prize) return null // protection habituelle recommandée en doc
  const filename = prize.image.filename // NotNull
  return filename.endsWith('pdf')
    ? (
      <p>
        <span>Télécharger dans un nouvel onglet</span>
        <IconButton aria-label="OpenInNew" color="inherit" onClick={() => window.open(filename)}>
          <OpenInNew />
        </IconButton>
      </p>
      )
    : <img src={filename} alt={filename} height="300" />
}

// utilisé en : prize/list
const PrizeTeamLinkField = (_props: FieldProps) => {
  const prize = useRecordContext<Prize>()
  const createPath = useCreatePath()
  if (!prize) return null // protection habituelle recommandée en doc
  const team = prize.team // peut être Null
  if (team?.status === Status.Winner) // psam 12/24 #27, louche : si team_id non null, pas d'autre cas en DB que la team en statut winner
    return <Link to={createPath({ resource: 'team', type: 'show', id: team.id })}>Voir l'équipe gagnante</Link>
  /* psam 12/24 #27 ancien code ; aucun avantage ici à un FunctionField
  return (
    <FunctionField
      render={(record?: RaRecord) => {
        if (record && record.team) {
          if (record.team.status === Status.Winner) {
            return <Link to={createPath({ resource: 'team', type: 'show', id: record.team.id })}>Voir l'équipe gagnante</Link>
          }
        } else {
          return null
        }
      }}
    />
  )
  */
}

// utilisé en : prize/list
const PrizeTeamStatusField = (_props: FieldProps) => {
  /* psam 12/24 #27 ancien code ; aucun avantage ici à un FunctionField
  <FunctionField
    label="resources.score.fields.status"
    render={(record?: RaRecord) => {
      if (record && record.team) {
        if (record.team.status === Status.Winner) {
          return <Chip label="Remporté" color="secondary" onDelete={() => {}} deleteIcon={<Done />} />
        } else {
          return <Chip label="Participant" color="primary" />
        }
      } else {
        return null
      }
    }}
  />
  */
  const prize = useRecordContext<Prize>()
  if (!prize) return null // protection habituelle recommandée en doc
  const team = prize.team // peut être Null
  if (team) { // psam 12/24 #27, louche : si team_id non null, pas d'autre cas en DB que la team en statut winner
    return team.status === Status.Winner
      ? <Chip label="Remporté" color="secondary" onDelete={() => {}} deleteIcon={<Done />} />
      : <Chip label="Participant" color="primary" />
  }
}

export {
  PrizeChallengeLinkField,
  PrizeImageIcon,
  PrizeImageField,
  PrizeTeamLinkField,
  PrizeTeamStatusField,
}
