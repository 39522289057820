import { Link, useCreatePath, useRecordContext } from 'react-admin'
import type { FieldProps } from 'react-admin'

import type { Team } from '../types'

// utilisé en team/edit, list, show
const TeamChallengeLinkField = (_props: FieldProps) => {
  const team = useRecordContext<Team>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('challenge', { id: team.challenge.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{data.name}</Link>
  } else {
    return null
  }
  */
  if (!team) return null // protection habituelle recommandée en doc
  const challenge = team.challenge // NotNull
  return <Link to={createPath({ resource: 'challenge', type: 'show', id: challenge.id })}>{challenge.name}</Link>
}

export { TeamChallengeLinkField }
