import {
  Link,
  useCreatePath,
  useRecordContext,
} from 'react-admin'
import type { FieldProps} from 'react-admin'
import { Android, Apple } from '@mui/icons-material'
import { Chip } from '@mui/material'
import get from 'lodash/get'

import type { Device } from '../types'

// utilisé en : device/list, show
export const DeviceAccountLinkField = ({ source }: FieldProps) => {
  const device = useRecordContext<Device>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('account', { id: device.account?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: data.id })}>{data[props.source]}</Link>
  } else {
    return null
  }
  */
  if (!device) return null // protection habituelle recommandée en doc
  if (source === undefined) return null // pas attendu
  const value = get(device, source)
  return <Link to={createPath({ resource: 'account', type: 'show', id: device.account.id })}>{value}</Link>
}

// utilisé en : account/show, device/list, show
export const OsField = (_props: FieldProps) => {
  const device = useRecordContext<Device>()
  if (!device) return null // protection habituelle recommandée en doc
  return device.os === 'android'
    ? <Chip icon={<Android />} style={{ padding: 4, margin: 4 }} label={`Android ${device.osVersion}`} />
    : <Chip icon={<Apple />} style={{ padding: 4, margin: 4 }} label={`iOS ${device.osVersion}`} />
}
