import { Link, SelectInput, useCreatePath, useRecordContext, useTranslate } from 'react-admin'
import type { FieldProps } from 'react-admin'
import { Box, Grid } from '@mui/material'
import { BluetoothDisabled, BugReport, EmojiEvents, PhoneAndroid, Storage } from '@mui/icons-material'

import { format, differenceInSeconds } from 'date-fns'
import get from 'lodash/get'
import { VictoryChart, VictoryLabel, VictoryLine, VictoryTheme, VictoryZoomContainer } from 'victory'

import { EndedBy, type Session } from '../types'

// utilisé en : account/show, challenge/sessions, cscDevice/show, device/show, session/list, team/show
const SessionScoreField = (_props: FieldProps) => {
  const session = useRecordContext<Session>()
  if (!session) return null // protection habituelle recommandée en doc
  let style: any = {
    whiteSpace: 'nowrap'
  }
  switch (session.challenge.criteria) {
    case 'calories':
      return <span>{Number(session.calories / 1000).toFixed(2)} cal</span>
    case 'distance':
      if (session.distance === 0 || session.endedBy === EndedBy.SUSPICIOUS) {
        style.fontWeight = 600
        style.color = 'red'
      }
      return (
        <span style={style}>
          {`${Number(session.distance / 1000)
            .toFixed(2)
            .replace('.', ',')} km`}
        </span>
      )
    case 'duration':
      if (session.duration >= 3600) {
        return <span style={style}>1h</span>
      } else {
        return <span style={style}>{format(Number(session.duration * 1000), "m'min 'ss")}</span>
      }
  }
}

const SessionEndedByField = (_props: FieldProps) => {
  const record = useRecordContext<Session>()
  const translate = useTranslate()
  if (!record) return null // protection habituelle recommandée en doc
  let icon, content = ''
  switch (record.endedBy) {
    case EndedBy.API_SEND_DATA_WIN:
      icon = <EmojiEvents />
      content = translate('resources.session.endedBy.win')
      break
    case EndedBy.API_STOP:
      icon = <PhoneAndroid />
      content = translate('resources.session.endedBy.stop')
      break
    case EndedBy.BLUETOOTH_LOST:
      icon = <BluetoothDisabled />
      content = translate('resources.session.endedBy.bluetoothLost')
      break
    case EndedBy.FORCE:
      icon = <PhoneAndroid />
      content = translate('resources.session.endedBy.force')
      break
    case EndedBy.MANUAL:
      icon = <PhoneAndroid />
      content = translate('resources.session.endedBy.manual')
      break
    case EndedBy.SERVER_STOP_GHOST_SESSION:
      icon = <Storage />
      content = translate('resources.session.endedBy.serverGhost')
      break
    case EndedBy.SERVER_STOP_SESSION_TOO_OLD:
      icon = <Storage />
      content = translate('resources.session.endedBy.serverTooOld')
      break
    case EndedBy.SUSPICIOUS:
      icon = <BugReport />
      content = translate('resources.session.endedBy.suspicious')
      break
    case EndedBy.TIME_UP:
      icon = <PhoneAndroid />
      content = translate('resources.session.endedBy.timeup')
      break
    case EndedBy.TIMEOUT:
      icon = <PhoneAndroid />
      content = translate('resources.session.endedBy.timeout')
      break
    case EndedBy.WEBSOCKET_DISCONNECTED:
      icon = <Storage />
      content = translate('resources.session.endedBy.websocketDisconnected')
      break
    default:
      content = translate('resources.session.endedBy.unknown')
  }
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Box pl={1}>{content}</Box>
    </Box>
  )
}

const SessionEndedSelectInput = (props: any) => {
  const translate = useTranslate()
  return (
    <SelectInput
      alwaysOn={props.alwaysOn}
      source={props.source}
      resettable
      choices={[
        {
          id: EndedBy.TIME_UP,
          name: translate('resources.session.endedBy.timeup')
        },
        {
          id: EndedBy.TIMEOUT,
          name: translate('resources.session.endedBy.timeout')
        },
        {
          id: EndedBy.MANUAL,
          name: translate('resources.session.endedBy.manual')
        },
        {
          id: EndedBy.WEBSOCKET_DISCONNECTED,
          name: translate('resources.session.endedBy.websocketDisconnected')
        },
        {
          id: EndedBy.SUSPICIOUS,
          name: translate('resources.session.endedBy.suspicious')
        },
        {
          id: EndedBy.API_SEND_DATA_WIN,
          name: translate('resources.session.endedBy.win')
        },
        {
          id: EndedBy.SERVER_STOP_GHOST_SESSION,
          name: translate('resources.session.endedBy.serverGhost')
        },
        {
          id: EndedBy.SERVER_STOP_SESSION_TOO_OLD,
          name: translate('resources.session.endedBy.serverTooOld')
        },
        {
          id: EndedBy.BLUETOOTH_LOST,
          name: translate('resources.session.endedBy.bluetoothLost')
        },
        {
          id: EndedBy.FORCE,
          name: translate('resources.session.endedBy.force')
        },
        {
          id: EndedBy.API_STOP,
          name: translate('resources.session.endedBy.stop')
        }
      ]}
    />
  )
}

// utilisé en : challenge/sessions, cscDevice/show, session/edit, list, show, team/show
// avec 'source=' à account.pseudo|email
const SessionAccountLinkField = ({ source }: FieldProps) => {
  const session = useRecordContext<Session>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('account', { id: session.account?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: data.id })}>{data[props.source]}</Link>
  } else {
    return null
  }
  */
  if (!session) return null // protection habituelle recommandée en doc
  if (source === undefined) return null // pas attendu
  const value = get(session, source)
  return <Link to={createPath({ resource: 'account', type: 'show', id: session.account.id })}>{value}</Link>
}

// utilisé en : account/edit, show, cscDevice/show, device/show, session/edit, list, show
const SessionChallengeLinkField = (_props: FieldProps) => {
  const session = useRecordContext<Session>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('challenge', { id: session.challenge?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{data.name}</Link>
  } else {
    return null
  }
  */
  if (!session) return null // protection habituelle recommandée en doc
  const challenge = session.challenge
  return <Link to={createPath({ resource: 'challenge', type: 'show', id: challenge.id })}>{challenge.name}</Link>
}

// utilisé en : account/show, session/list, edit
const SessionDeviceLinkField = (_props: FieldProps) => {
  const session = useRecordContext<Session>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('device', { id: session.device?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'device', type: 'show', id: data.id })}>{data.brand + ' ' + data.model}</Link>
  } else {
    return null
  }
  */
  if (!session) return null // protection habituelle recommandée en doc
  if (!session.device) return null // 1° pas de field avant 14/11/2022, 2° peut être non rempli, cause bug en 5.2.0
  const device = session.device
  return <Link to={createPath({ resource: 'device', type: 'show', id: device.id })}>{`${device.brand} ${device.model}`}</Link>
}

const SessionRowStyle = (record: Session): any => {
  let style = {}
  if (!record) {
    return style
  }
  if (record.endedBy === EndedBy.TIME_UP || record.endedBy === EndedBy.API_SEND_DATA_WIN) {
    return {
      borderLeftColor: 'rgb(76, 175, 80)',
      backgroundColor: 'rgba(76, 175, 80, 0.03)',
      borderLeftWidth: 3,
      borderLeftStyle: 'solid'
    }
  } else if (record.endedBy === EndedBy.SUSPICIOUS) {
    return {
      borderLeftColor: 'rgb(244, 67, 54)',
      backgroundColor: 'rgba(244, 67, 54, 0.03)',
      borderLeftWidth: 3,
      borderLeftStyle: 'solid'
    }
  } else if (record.distance === 0) {
    return {
      borderLeftColor: 'rgb(255, 152, 0)',
      backgroundColor: 'rgba(255, 152, 0, 0.03)',
      borderLeftWidth: 3,
      borderLeftStyle: 'solid'
    }
  } else {
    return {
      borderLeftColor: 'transparent',
      borderLeftWidth: 3,
      borderLeftStyle: 'solid'
    }
  }
}

const RpmGraph = (props: any) => {
  const record = useRecordContext<Session>(props)
  const getData = (field: string) => {
    let data = []
    if (field !== 'rpm') {
      data.push({ x: 0, y: 0 })
    }
    data.push(
      ...record.rpms.map((rpm) => {
        const duration = differenceInSeconds(new Date(rpm.date), new Date(record.deviceStartDate))
        return { x: duration, y: rpm[field] }
      })
    )
    return data
  }
  if (record.rpms) {
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <VictoryChart
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer zoomDimension="x" minimumZoom={{ x: 20, y: 1 }} />}>
            <VictoryLine
              style={{
                data: { stroke: '#c43a31' },
                parent: { border: '1px solid #ccc' }
              }}
              data={getData('distance')}
            />
            <VictoryLabel x={55} y={40} text="Distance (m)" />
            <VictoryLabel x={310} y={300} text="Durée (s)" />
          </VictoryChart>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <VictoryChart
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer zoomDimension="x" minimumZoom={{ x: 20, y: 1 }} />}>
            <VictoryLine
              style={{
                data: { stroke: '#ff00ff' },
                parent: { border: '1px solid #ccc' }
              }}
              data={getData('calories')}
            />
            <VictoryLabel x={55} y={40} text="Calories" />
            <VictoryLabel x={310} y={300} text="Durée (s)" />
          </VictoryChart>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <VictoryChart
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer zoomDimension="x" minimumZoom={{ x: 20, y: 1 }} />}>
            <VictoryLine
              style={{
                data: { stroke: '#ff00ff' },
                parent: { border: '1px solid #ccc' }
              }}
              data={getData('rpm')}
            />
            <VictoryLabel x={55} y={40} text="Tours/min" />
            <VictoryLabel x={310} y={300} text="Durée (s)" />
          </VictoryChart>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export {
  SessionAccountLinkField,
  SessionChallengeLinkField,
  SessionDeviceLinkField,
  SessionEndedByField,
  SessionEndedSelectInput,
  SessionRowStyle,
  SessionScoreField,
  RpmGraph
}
