import {
  Datagrid,
  Link,
  List,
  ShowButton,
  TextField,
  TopToolbar,
  useCreatePath,
  useRecordContext
} from 'react-admin'
import type { FieldProps, ListProps } from 'react-admin'

import { ShortDateField } from '../components/ShortDateTimeField'
import type { Order } from '../types'

// utilisé seulement ici
const OrderChallengeLinkField = (_props: FieldProps) => {
  const order = useRecordContext<Order>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data } = useGetOne('challenge', { id: order.challenge.id })
  if (data) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: order.challenge.id })}>{data.name}</Link>
  } else {
    return null
  }
  */
  if (!order) return null // protection habituelle recommandée en doc
  const challenge = order.challenge // peut être Null
  if (challenge)
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: challenge.id })}>{challenge.name}</Link>
}

// utilisé seulement ici
const OrderAccountLinkField = (_props: FieldProps) => {
  const order = useRecordContext<Order>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data } = useGetOne('account', { id: order.account.id })
  if (data) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: order.account.id })}>{`${data.pseudo} (${data.email})`}</Link>
  } else {
    return null
  }
  */
  if (!order) return null // protection habituelle recommandée en doc
  const account = order.account // peut être Null
  if (account)
    return <Link to={createPath({ resource: 'account', type: 'show', id: account.id })}>{`${account.pseudo} (${account.email})`}</Link>
}

const OrderListActions = (props: any) => <TopToolbar />

const OrderList = (props: ListProps) => (
  <List {...props} actions={<OrderListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ShortDateField source="creationDate" />
      <OrderChallengeLinkField label="Challenge" sortable={false} />
      <OrderAccountLinkField label="resources.order.labels.partner" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
)

export default OrderList
