import {
  Datagrid,
  FilterButton,
  List,
  NumberField,
  Pagination,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useRecordContext
} from 'react-admin'
import type { FieldProps, ListProps } from 'react-admin'

import { PrizeChallengeLinkField, PrizeTeamLinkField, PrizeTeamStatusField } from '../components/PrizeFields'
import type { Prize } from '../types'

// utilisé ici seulement ; attention il en existe un autre en PrizeFields, similaire mais différent.
const PrizeImageField = (_props: FieldProps) => {
  const prize = useRecordContext<Prize>()
  if (!prize) return null // protection habituelle recommandée en doc
  const filename = prize.image.filename // NotNull
  return <img src={filename} alt={filename} height="50" />
}

const prizeFilters = [
  <SearchInput fullWidth source="s" alwaysOn placeholder="Nom du Challenge" />,
  <SelectInput
    style={{ minWidth: 200 }}
    alwaysOn
    source="challenge.status"
    choices={[
      { id: 'ended', name: 'Oui' },
      { id: 'ongoing', name: 'Non' }
    ]}
  />
]

const PrizeListActions = (props: any) => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
)

const PrizeList = (props: ListProps) => (
  <List
    {...props}
    filters={prizeFilters}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    actions={<PrizeListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <PrizeChallengeLinkField source="challenge.name" sortable={false} />
      <NumberField source="prizeOrder" />
      <PrizeImageField label="Image" sortable={false} />
      <PrizeTeamStatusField source="team.status" sortable={false} />
      <PrizeTeamLinkField label="resources.prize.labels.team" sortable={false} />
    </Datagrid>
  </List>
)

export default PrizeList
