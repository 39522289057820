import {
  Datagrid,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useTranslate
} from 'react-admin'
import type { ShowProps} from 'react-admin'
import { Grid, FormControl, FormHelperText } from '@mui/material'

import { DeviceAccountLinkField, OsField } from '../components/DeviceFields'
import { SessionChallengeLinkField, SessionEndedByField, SessionRowStyle, SessionScoreField } from '../components/SessionFields'
import { ShortDateField, ShortDateTimeField } from '../components/ShortDateTimeField'

const DeviceShow = (props: ShowProps) => {
  const translate = useTranslate()
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.brand')}</FormHelperText>
          <TextField source="brand" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.model')}</FormHelperText>
          <TextField source="model" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.os')}</FormHelperText>
          <OsField source="os" />
        </FormControl>
        <Grid container>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ display: 'flex', marginBottom: 4 }}>
              <FormHelperText>{translate('resources.device.fields.creationDate')}</FormHelperText>
              <ShortDateField source="creationDate" />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ display: 'flex', marginBottom: 4 }}>
              <FormHelperText>{translate('resources.device.fields.updateDate')}</FormHelperText>
              <ShortDateField source="updateDate" />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.labels.owner')}</FormHelperText>
          <DeviceAccountLinkField source="account.pseudo" />
        </FormControl>
        <ReferenceManyField reference="session" target="device.id" label="resources.device.helpers.sessions" pagination={<Pagination />}>
          <Datagrid bulkActionButtons={false} rowStyle={SessionRowStyle}>
            <ShortDateTimeField source="deviceStartDate" />
            <ShortDateTimeField source="deviceEndDate" />
            <SessionEndedByField source="endedBy" />
            <SessionChallengeLinkField source="challenge.name" sortable={false} />
            <SessionScoreField label="Score" />
            <ShowButton resource="session" label="Voir" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default DeviceShow
