import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateInput,
  DateTimeInput,
  FileInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  Labeled,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { add, format } from 'date-fns'
import { jwtDecode } from 'jwt-decode'

import { ChallengeCriteriaInput } from '../components/ChallengeFields'
import type { AccountPayload } from '../core/api'
import { Role } from '../types'

const __DEV__ = process.env.NODE_ENV === 'development'

const dv = {
  name: __DEV__ ? 'Nom du challenge' : '',
  description: __DEV__ ? 'Description du challenge' : '',
  url: __DEV__ ? 'https://www.queeny.fr' : '',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: format(add(new Date(), { months: 1 }), 'yyyy-MM-dd'),
  scope: __DEV__ ? 'private' : '',
  code: __DEV__ ? Math.floor(Math.random() * 10000000000) : ''
}

const ChallengeCreate = () => {
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const [create] = useCreate()

  const onSubmit = async (data: any) => {
    try {
      await create('challenge', { data }, { returnPromise: true })
      notify('resources.challenge.helpers.created', { type: 'success' })
      redirect('list', 'challenge')
    } catch (error: any) {
      notify(`resources.errors.${error.message}`, { type: 'warning' })
    }
  }

  const renderSelectPartner = () => {
    const token = localStorage.getItem('token') || '{}'
    const decoded = jwtDecode<AccountPayload>(token)
    if (decoded.role === Role.Admin) {
      return ( // psam 01/25 #23 élimination ressource fictive 'account_admin_or_partner' par ajout filter et filterToQuery
        <Grid item sm={12} md={6}>
          <ReferenceInput source="account" reference="account" filter={{ role: [Role.Advertiser, Role.Admin] }}>
            <AutocompleteInput
              label="resources.challenge.labels.partner"
              validate={required()}
              optionText={(record) => {
                const suffix = record.role === Role.Admin ? ' (admin)' : ' (annonceur)'
                return record.email + suffix
              }}
              filterToQuery={(searchText: string) => ({ s: searchText }) /* paginate() attend du 's' */}
            />
          </ReferenceInput>
        </Grid>
      )
    } else {
      return false
    }
  }

  return (
    <Create>
      <TabbedForm onSubmit={onSubmit} warnWhenUnsavedChanges>
        <FormTab label={translate('resources.challenge.tabs.intro')}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <TextInput source="name" label="resources.challenge.fields.name"
                fullWidth
                defaultValue={dv.name} validate={required()}
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput source="description" label="resources.challenge.fields.description"
                fullWidth multiline
                defaultValue={dv.description} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput source="url" label="resources.challenge.labels.url" helperText="resources.challenge.helpers.url"
                fullWidth type="url"
                defaultValue={dv.url} validate={[required(), regex(/^https/, "L'URL n'est pas au bon format")]}
              />
            </Grid>
            {renderSelectPartner()}
            <Grid item sm={12} md={3}>
              <DateInput source="startDate" label="resources.challenge.fields.startDate"
                fullWidth
                defaultValue={dv.startDate} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <DateInput source="endDate" label="resources.challenge.fields.endDate"
                fullWidth
                defaultValue={dv.endDate} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <NumberInput source="priority" label="resources.challenge.fields.priority" helperText="0..100"
                fullWidth min="0" max="100" step="5"
                defaultValue={50} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <NumberInput source="sessionDuration" label="resources.challenge.fields.sessionDuration" helperText="10..60"
                fullWidth min="10" max="60" step="5"
                parse={(v: string) => parseInt(v) * 60} format={(v: number) => v / 60}
                defaultValue={600} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <SelectInput source="scope" label="resources.challenge.fields.scope"
                fullWidth
                choices={[
                  { id: 'public', name: 'resources.challenge.scope.public' },
                  { id: 'private', name: 'resources.challenge.scope.private' }
                ]}
                defaultValue={dv.scope} validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.scope === 'private' && (
                    <TextInput fullWidth source="code" defaultValue={dv.code} validate={required()} {...rest} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.images')}>
          <Typography variant="caption">Ajouter dans cette section une ou plusieurs images pour votre Challenge.</Typography>
          <Typography variant="caption">
            Il est obligatoire de mettre au moins une bannière. Si vous mettez plusieurs images, un carrousel s'affichera dans l'App.
          </Typography>
          <Typography variant="caption" marginBottom={2}>
            Chaque bannière ne doit pas dépasser 3 Mo. Un format rectangulaire 2:1 de 1000px par 500px est recommandé.
          </Typography>
          <ImageInput source="images" accept="image/*" maxSize={3000000} multiple validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label={translate('resources.challenge.fields.objective')}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput source="type" label="resources.challenge.labels.type"
                margin="none"
                choices={[
                  { id: 'solo', name: 'resources.challenge.fields.solo' },
                  { id: 'team', name: 'resources.challenge.fields.team' }
                ]}
                defaultValue="solo"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && (
                    <NumberInput source="maxTeamSize" label="resources.challenge.fields.maxTeamSize" helperText="2..40"
                      fullWidth min="2" max="40"
                      validate={required()} {...rest}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && (
                    <BooleanInput source="onlyAdminCanCreateTeams" label="resources.challenge.fields.onlyAdminCanCreateTeams"
                      defaultValue={false} {...rest}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && formData.onlyAdminCanCreateTeams === true && (
                    <ArrayInput source="teams" label="resources.challenge.fields.teams" {...rest}>
                      <SimpleFormIterator>
                        <TextInput source="name" label="resources.team.fields.name" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput source="criteria" label="resources.challenge.labels.criteria"
                margin="none"
                choices={[
                  { id: 'distance', name: 'resources.challenge.criterias.distance' },
                  { id: 'calories', name: 'resources.challenge.criterias.calories' },
                  { id: 'duration', name: 'resources.challenge.criterias.duration' }
                ]}
                defaultValue="distance"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  if (formData.criteria === 'distance') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.distance" suffix="Km" {...rest} />
                  } else if (formData.criteria === 'calories') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.calories" suffix="KCal" {...rest} />
                  } else {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.duration" suffix="Heures" {...rest} />
                  }
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <Labeled label="resources.challenge.labels.global">
                <BooleanInput source="global" defaultValue={false} />
              </Labeled>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.prizes')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.helpers.generatePrizes">
                <BooleanInput source="generatePrizes" defaultValue={false} />
              </Labeled>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.generatePrizes ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <NumberInput source="qrCodePoolSize" label="resources.challenge.fields.qrCodePoolSize"
                        fullWidth
                        min="1" max="200"
                        defaultValue={1}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <NumberInput source="qrCodeCardCredit" label="resources.challenge.fields.qrCodeCardCredit"
                        fullWidth
                        min="1" max="10"
                        defaultValue={1}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <RadioButtonGroupInput source="qrCodeIsMoney" label="resources.challenge.labels.qrCodeIsMoney"
                        margin="none"
                        choices={[
                          { id: 'true', name: 'resources.challenge.qrCodeIsMoney.true' },
                          { id: 'false', name: 'resources.challenge.qrCodeIsMoney.false' },
                        ]}
                        defaultValue="true"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">Restreindre les Groupes Machines (par défaut, tous sont actifs).</Typography>
                      <SelectArrayInput source="qrCodeGroups" label="resources.challenge.fields.qrCodeGroups"
                        fullWidth
                        choices={[
                          { id: 6708, name: 'Patismatique' },
                          { id: 6961, name: 'Gratuite' },
                          { id: 7113, name: 'Snack' },
                          { id: 7114, name: 'Boissons chaudes' },
                          { id: 9276, name: 'Autre' }
                        ]}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption">
                        Ajouter dans cette section une ou plusieurs récompenses pour votre Challenge.
                      </Typography>
                      <Typography variant="caption">Chaque lot à remporter ne doit pas dépasser 3 Mo.</Typography>
                      <Typography variant="caption">
                        Dès qu'un participant (ou une équipe) remporte un Challenge, il reçoit un lot par email ; accessible également dans
                        l'App.
                      </Typography>
                      <Typography variant="caption" marginBottom={2}>
                        La première image correspond au premier lot, la deuxième image au deuxième lot, et ainsi de suite.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Labeled label="resources.challenge.helpers.prizesArePdf">
                        <BooleanInput source="prizesArePdf" label="Utiliser le format PDF" defaultValue={false} />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                      {formData.prizesArePdf ? (
                        <FileInput source="prizes" accept={'application/pdf'} maxSize={3000000} multiple validate={required()}
                          placeholder={<p>Déposez les PDFs à téléverser, ou cliquez pour en sélectionner.</p>}>
                          <ImageField source="src" title="title" />
                        </FileInput>
                      ) : (
                        <ImageInput source="prizes" accept={'image/*'} maxSize={3000000} multiple validate={required()}>
                          <ImageField source="src" title="title" />
                        </ImageInput>
                      )}
                    </Grid>
                  </>
                )
              }
            </FormDataConsumer>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.notification')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.helpers.notification">
                <BooleanInput fullWidth source="defaultNotify" defaultValue={true} />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.defaultNotify === false && (
                    <DateTimeInput fullWidth source="sentDate" validate={[required(), minValue(new Date())]} {...rest} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default ChallengeCreate
