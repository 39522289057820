import { Fragment, useState } from 'react'
import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  FunctionField,
  Link,
  Loading,
  NumberField,
  SingleFieldList,
  Title,
  TextField,
  TopToolbar,
  useCreatePath,
  useGetList,
  useRecordContext,
} from 'react-admin'
import type { FieldProps, SortPayload } from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import { Chip, LinearProgress, Paper, Typography } from '@mui/material'
import { ChevronLeft, Done } from '@mui/icons-material'

import { formatDuration, intervalToDuration } from 'date-fns'
import { fr } from 'date-fns/locale'
import get from 'lodash/get'

import { type Rank, Status } from '../types'

// s'utilise seulement ici, et avec team à 1 account
const AccountLinkField = ({ source }: FieldProps) => {
  const rank = useRecordContext<Rank>()
  const createPath = useCreatePath()
  /* ancien code, éliminé car data déjà disponible ; conservé pour comprendre l'intention originelle
  const { data, isLoading } = useGetOne('account', { id: rank.team.accountsWithSum[0].id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: rank.team.accountsWithSum[0].id })}>{data[props.source]}</Link>
  } else {
    return null
  }
  */
  if (!rank) return null // protection habituelle recommandée en doc
  if (source === undefined) return null // pas attendu
  const value = get(rank.team.accountsWithSum[0], source)
  return <Link to={createPath({ resource: 'account', type: 'show', id: rank.team.accountsWithSum[0].id })}>{value}</Link>
}

const ScoreField = (_props: FieldProps) => {
  const rank = useRecordContext<Rank>()
  if (!rank) return null // protection habituelle recommandée en doc
  const criteria = rank.challenge.criteria
  const score = rank.score
  if (criteria === 'distance') {
    return (score < 1000) ? <span>{score} m</span> : <span>{score / 1000} km</span>
  } else if (criteria === 'calories') {
    return (score < 1000) ? <span>{score} cal</span> : <span>{score / 1000} kcal</span>
  } else {
    return <span>{formatDuration(intervalToDuration({ start: 0, end: score * 1000 }), { locale: fr })}</span>
  }
}

const NoRanking = () => {
  return (
    <div style={{ margin: 8 }}>
      <Typography variant="body1">Pas encore de classement pour ce Challenge</Typography>
    </div>
  )
}

const ChallengeRanking = (props: any) => {
  const [sort] = useState<SortPayload>({ field: 'id', order: 'ASC' })
  const { id } = useParams()
  const navigate = useNavigate()
  const createPath = useCreatePath()

  const { data, isLoading, error } = useGetList(`challenge/${id}/ranking`, {
    pagination: { page: 1, perPage: 25 },
    sort,
    filter: {}
  })

  if (!data || isLoading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR: {error.message}</p>
  }
  return (
    <Fragment>
      <Title title={`Challenge n°${id} : classement`} />
      <TopToolbar>
        <Button color="primary" startIcon={<ChevronLeft />} onClick={() => navigate(`/challenge/${id}/show`)} label="Retour au Challenge" />
        <div style={{ width: 1, flex: 1 }} />
      </TopToolbar>
      <Paper>
        <Datagrid data={data} sort={sort} empty={<NoRanking />}>
          <NumberField source="id" label="resources.rank.fields.id" />
          <ScoreField label="Score" />
          {data[0]?.challenge?.type === 'team' && <TextField source="team.name" label="resources.team.fields.name" />}
          {data[0]?.challenge?.type === 'team' || data[0]?.challenge?.global ? (
            <ArrayField source="team.accountsWithSum" label="resources.account.fields.pseudo">
              <SingleFieldList linkType="show" resource="account">
                <ChipField source="pseudo" />
              </SingleFieldList>
            </ArrayField>
          ) : (
            <AccountLinkField source="pseudo" label="resources.account.fields.pseudo" />
          )}
          <NumberField source="percent" options={{ style: 'percent' }} label="resources.rank.fields.percent" />
          <FunctionField
            render={(record: any) => {
              return <LinearProgress variant="determinate" value={record.percent * 100} color={record.percent === 1 ? 'success' : 'info'} />
            }}
          />
          <FunctionField
            label="resources.team.fields.status"
            render={(record: any) => {
              if (record.status === Status.Winner) {
                return <Chip label="Remporté" color="success" onDelete={() => {}} deleteIcon={<Done />} />
              } else {
                return <Chip label="Participant" color="primary" />
              }
            }}
          />
          <FunctionField
            render={(record: any) => {
              return (
                <Link
                  style={{ textTransform: 'uppercase', fontSize: '0.8125rem', fontWeight: 500 }}
                  to={createPath({ resource: 'team', type: 'show', id: record.team.id })}>
                  Voir
                </Link>
              )
            }}
          />
        </Datagrid>
      </Paper>
    </Fragment>
  )
}

export default ChallengeRanking
