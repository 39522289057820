import {
  AutocompleteInput,
  BulkDeleteButton,
  DatagridConfigurable,
  DateInput,
  DeleteButton,
  EditButton,
  FilterButton,
  List,
  Pagination,
  ReferenceInput,
  SelectColumnsButton,
  ShowButton,
  TopToolbar
} from 'react-admin'
import type { ListProps} from 'react-admin'

import {
  SessionAccountLinkField,
  SessionChallengeLinkField,
  SessionDeviceLinkField,
  SessionEndedByField,
  SessionEndedSelectInput,
  SessionRowStyle,
  SessionScoreField
} from '../components/SessionFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { useCanAccess } from '../core/hooks'
import { CrudActions } from '../types'

const sessionFilters = [
  <SessionEndedSelectInput alwaysOn source="endedBy" />,
  <DateInput alwaysOn source="deviceStartDate" />,
  // ReferenceInput fait appeler dataProvider.getMany()
  // AutocompleteInput fait appeler dataProvider.getList()
  /* psam 01/25 #23 pour challenge, 'reference' était une ressource fictive "challenge_session",
  pour être retravaillée dans .getList() comme un cas particulier, c'est-à-dire une recherche du motif dans le 'name'.
  Mais sans traitement particulier équivalent en .getMany() d'où 404.
  Simplifier et éliminer cette notion fictive.
  */
  <ReferenceInput label="Challenges" source="challenge.id" reference="challenge">
    <AutocompleteInput
      label="Challenge"
      style={{ minWidth: 260 }}
      optionText={r => r ? `${r.id} - ${r.name}` : ''}
      filterToQuery={(searchText: string) => ({ s: searchText }) /* psam 01/25 #23 réhabilitation, paginate() attend du 's' */}
    />{/* psam 11/24 correctif #13 filterToQuery={(searchText: string) => ({ s: [searchText] })}*/}
  </ReferenceInput>,
  <ReferenceInput label="Utilisateurs" source="account.id" reference="account">
    <AutocompleteInput
      label="Utilisateur"
      optionText={r => r ? `${r.id} - ${r.pseudo}` : ''}
      filterToQuery={(searchText: string) => ({ s: searchText }) /* paginate() attend du 's' */}
    />{/* psam 11/24 correctif #13 et #16 */}
  </ReferenceInput>
]

const SessionListBulkActions = () => <BulkDeleteButton mutationMode="pessimistic" />

const SessionListActions = (props: any) => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
)

const SessionList = (props: ListProps) => {
  const { canAccess: canAccessEdit } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'session' })
  const { canAccess: canAccessDelete } = useCanAccess({ action: CrudActions.DeleteOne, resource: 'session' })
  const { canAccess: canAccessAccount } = useCanAccess({ action: CrudActions.ReadAll, resource: 'account' })
  return (
    <List
      {...props}
      filters={sessionFilters}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      sort={{ field: 'deviceStartDate', order: 'DESC' }}
      actions={<SessionListActions />}>
      <DatagridConfigurable bulkActionButtons={<SessionListBulkActions />} rowStyle={SessionRowStyle}>
        <ShortDateTimeField source="deviceStartDate" />
        <SessionEndedByField source="endedBy" />
        <SessionChallengeLinkField source="challenge.name" sortable={false} />
        {canAccessAccount && <SessionAccountLinkField source="account.pseudo" sortable={false} />}
        {canAccessAccount && <SessionDeviceLinkField source="device" sortable={false} />}
        <SessionScoreField label="Score" />
        <ShowButton label="" />
        {canAccessEdit && <EditButton label="" />}
        {canAccessDelete && <DeleteButton label="Supprimer" mutationMode="pessimistic" />}
      </DatagridConfigurable>
    </List>
  )
}

export default SessionList
