import React, { useState } from 'react'
import {
  ArrayField,
  BooleanInput,
  Confirm,
  Datagrid,
  DateInput,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  regex,
  required,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate
} from 'react-admin'
import { Button, Chip, Grid, Tooltip, Typography } from '@mui/material'
import { Done } from '@mui/icons-material'
import { isBefore } from 'date-fns'

import { ChallengeCriteriaInput } from '../components/ChallengeFields'
import { PrizeImageIcon, PrizeImageField } from '../components/PrizeFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import Api from '../core/api'
import { type Challenge, type Image, Status } from '../types'

const ChallengeImageGrid = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  const refresh = useRefresh()
  const notify = useNotify()
  const [open, setOpen] = useState<boolean>(false)
  const [imageToDelete, setImageToDelete] = useState<Image | null>(null)

  const deleteImage = async () => {
    if (!imageToDelete) {
      return
    }
    setOpen(false)
    const res = await Api.delete(`challenge/${record.id}/image/${imageToDelete.id}`).json()
    if (res) {
      refresh()
      notify(`resources.challenge.helpers.imageDeleted`, { type: 'success' })
    } else {
      notify(`resources.challenge.helpers.imageNotDeleted`, { type: 'error' })
    }
  }

  if (record.images && record.images.length > 0) {
    return (
      <>
        <Grid container spacing={3}>
          {record.images.map((image) => (
            <>
              <Grid item xs={10} key={`challenge-image-${image.id}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <img src={image.filename} alt={image.filename} width="600" height="300" />
                <div
                  style={{
                    backgroundColor: image.averageColor,
                    width: 40,
                    height: 40,
                    marginLeft: 20,
                    border: 1,
                    borderColor: 'black',
                    borderStyle: 'solid'
                  }}
                />
              </Grid>
              <Grid item xs={2} key={`challenge-delete-image-${image.id}`}>
                <Tooltip
                  disableHoverListener={record.images.length > 1}
                  title={'Vous ne pouvez pas supprimer une bannière s’il n’y a qu’une seule image'}>
                  <span>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => {
                        setImageToDelete(image)
                        setOpen(true)
                      }}
                      disabled={record.images.length <= 1}>
                      Supprimer
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </>
          ))}
        </Grid>
        <Confirm
          isOpen={open}
          title="Confirmer votre action"
          content="Êtes-vous sûr de vouloir supprimer cette image ?"
          onConfirm={deleteImage}
          onClose={() => setOpen(false)}
        />
      </>
    )
  } else {
    return null
  }
}

const ChallengeEdit = (props: any) => {
  const translate = useTranslate()
  return (
    <Edit mutationMode="pessimistic">
      <TabbedForm>
        <FormTab label={translate('resources.challenge.tabs.intro')}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="name" label="resources.challenge.fields.name" validate={required()} />
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth source="description" label="resources.challenge.fields.description" multiline validate={required()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                fullWidth
                source="url"
                label="resources.challenge.labels.url"
                validate={[required(), regex(/^https/, "L'URL n'est pas au bon format")]}
                helperText="resources.challenge.helpers.url"
                type="url"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Labeled label="resources.challenge.labels.partner">
                <TextField source="createdBy.email" />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={3}>
              <DateInput fullWidth source="startDate" label="resources.challenge.fields.startDate" validate={required()} />
            </Grid>
            <Grid item sm={12} md={3}>
              <DateInput fullWidth source="endDate" label="resources.challenge.fields.endDate" validate={required()} />
            </Grid>
            <Grid item sm={12} md={3}>
              <NumberInput
                fullWidth
                source="priority"
                label="resources.challenge.fields.priority"
                min="0"
                max="100"
                defaultValue="50"
                step="5"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Labeled label="resources.challenge.fields.sessionDuration">
                <FunctionField render={(record: any) => `${record.sessionDuration / 60} min`} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={3}>
              <SelectInput
                fullWidth
                validate={required()}
                source="scope"
                label="resources.challenge.fields.scope"
                defaultValue={'public'}
                choices={[
                  { id: 'public', name: 'resources.challenge.scope.public' },
                  { id: 'private', name: 'resources.challenge.scope.private' }
                ]}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.scope === 'private' && <TextInput fullWidth source="code" {...rest} validate={required()} />
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.images')}>
          <Labeled label="resources.challenge.labels.images">
            <ChallengeImageGrid />
          </Labeled>
          <ImageInput
            source="images"
            accept="image/*"
            maxSize={3000000}
            multiple
            label="Si vous le voulez, vous pouvez ajouter des nouvelles bannières à ce Challenge.">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label={translate('resources.challenge.fields.objective')}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput
                margin="none"
                source="type"
                label="resources.challenge.labels.type"
                choices={[
                  { id: 'solo', name: 'resources.challenge.fields.solo' },
                  { id: 'team', name: 'resources.challenge.fields.team' }
                ]}
                defaultValue="solo"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && (
                    <NumberInput
                      fullWidth
                      source="maxTeamSize"
                      min="2"
                      max="40"
                      label="resources.challenge.fields.maxTeamSize"
                      step="2"
                      {...rest}
                      validate={required()}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput
                margin="none"
                source="criteria"
                label="resources.challenge.labels.criteria"
                choices={[
                  { id: 'distance', name: 'resources.challenge.criterias.distance' },
                  { id: 'calories', name: 'resources.challenge.criterias.calories' },
                  { id: 'duration', name: 'resources.challenge.criterias.duration' }
                ]}
                defaultValue="distance"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData.criteria === 'distance') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.distance" suffix="Km" />
                  } else if (formData.criteria === 'calories') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.calories" suffix="KCal" />
                  } else {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.duration" suffix="Heures" />
                  }
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <Labeled label="resources.challenge.labels.global">
                <BooleanInput source="global" defaultValue={false} />
              </Labeled>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.prizes')}>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              isBefore(new Date(formData.startDate), new Date()) && (
                <Typography variant="caption">Vous ne pouvez pas modifier les lots d'un Challenge qui a commencé</Typography>
              )
            }
          </FormDataConsumer>
          <ArrayField source="prizes">
            <Datagrid bulkActionButtons={false}>
              <NumberField source="prizeOrder" label="resources.prize.fields.prizeOrder" />
              <NumberField source="team.ranking" label="resources.team.fields.ranking" />
              <PrizeImageIcon source="image" label="resources.challenge.labels.prizes" />
              <PrizeImageField source="image" label="resources.prize.labels.prizes" />
              <FunctionField
                label="Gagné ?"
                render={(record: any) => {
                  if (record.team?.status === Status.Winner) {
                    return <Chip label="Remporté" color="success" onDelete={() => {}} deleteIcon={<Done />} />
                  } else {
                    return <Chip label="Non remporté" color="default" />
                  }
                }}
              />
              <ShortDateTimeField source="updateDate" label="resources.prize.fields.updateDate" />
            </Datagrid>
          </ArrayField>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              isBefore(new Date(), new Date(formData.startDate)) && (
                <ImageInput source="prizes" accept="image/*" maxSize={4000000} multiple>
                  <ImageField source="src" title="title" />
                </ImageInput>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ChallengeEdit
