import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  CloneButton,
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  Labeled,
  Link,
  NumberField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  useCreatePath,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate
} from 'react-admin'
import { BarChart, Done, Group, Person, QueryStats, Timer } from '@mui/icons-material'
import { Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import { isBefore } from 'date-fns'

import { ChallengeGlobalProgress, ChallengeOngoingField, ChallengeStatusField, TranslatedChipField } from '../components/ChallengeFields'
import { PrizeImageIcon, PrizeImageField } from '../components/PrizeFields'
import { ShortDateField, ShortDateTimeField } from '../components/ShortDateTimeField'
import Api from '../core/api'
import { useCanAccess } from '../core/hooks'
import useProfile from '../core/useProfile'
import { type Challenge, ChallengeStatus, CrudActions, Status } from '../types'
import { humanizeDuration } from '../utils'

const ChallengeCreatedByField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  const createPath = useCreatePath()
  const { data } = useGetOne('account', { id: record.createdBy.id })
  if (data) {
    return (
      <Labeled label="resources.challenge.labels.partner">
        <Link to={createPath({ resource: 'account', type: 'show', id: data.id })}>{data.email}</Link>
      </Labeled>
    )
  } else {
    return null
  }
}

const ChallengeTypeField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record.type === 'solo') {
    return (
      <Box display="flex" alignItems="center">
        <Person />
        <Box pl={1}>En solo</Box>
      </Box>
    )
  } else {
    return (
      <Box display="flex" alignItems="center">
        <Group />
        <Box pl={1}>En équipe</Box>
      </Box>
    )
  }
}

const ChallengeCriteriaField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record.criteria === 'distance') {
    return (
      <Box display="flex" alignItems="center">
        <Box pl={1}>{record.objective / 1000} Km</Box>
      </Box>
    )
  } else if (record.criteria === 'calories') {
    return (
      <Box display="flex" alignItems="center">
        <Box pl={1}>{record.objective / 1000} KCal</Box>
      </Box>
    )
  } else {
    return (
      <Box display="flex" alignItems="center">
        <Box pl={1}>{humanizeDuration(record.objective)}</Box>
      </Box>
    )
  }
}

const CodeField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record.code) {
    return (
      <Labeled source="Code">
        <ChipField source="code" />
      </Labeled>
    )
  } else {
    return null
  }
}

const Title = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record) {
    return (
      <span>
        Challenge n°{record.id} : {record.name}
      </span>
    )
  } else {
    return <span>Chargement du Challenge...</span>
  }
}

const ChallengeShowActions = (props: any) => {
  const notify = useNotify()
  const navigate = useNavigate()
  const redirect = useRedirect()
  const profile = useProfile()

  const record = useRecordContext<Challenge>(props)
  const [loading, setLoading] = useState<boolean>(false)
  const [showValidateDialog, setShowValidateDialog] = useState<boolean>(false)
  const { canAccess: canAccessCreate } = useCanAccess({ action: CrudActions.CreateOne, resource: 'challenge' })
  const { canAccess: canAccessDelete } = useCanAccess({ action: CrudActions.DeleteAll, resource: 'challenge' })
  const { canAccess: canAccessEdit } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'challenge' })

  let disabled = false
  if (record && isBefore(new Date(), new Date(record.startDate))) {
    disabled = true
  } else if (record && (record.status === ChallengeStatus.DRAFT || record.status === ChallengeStatus.TO_VALIDATE)) {
    disabled = true
  }

  const canEditOrFinalizeDraft = canAccessEdit && record && record.status === ChallengeStatus.DRAFT
  const canValidate = profile.isAdmin() && canAccessEdit && record && record.status === ChallengeStatus.TO_VALIDATE

  /**
   *
   */
  const finalizeDraft = async () => {
    setLoading(true)
    await Api.patch(`challenge/${record.id}/finalize-draft`, {})
    setLoading(false)
    setShowValidateDialog(false)
    notify(`resources.challenge.helpers.finalizeDraft`, { type: 'success' })
    redirect('list', 'challenge')
  }

  /**
   *
   */
  const validate = async () => {
    setLoading(true)
    await Api.patch(`challenge/${record.id}/validate`, {})
    setLoading(false)
    setShowValidateDialog(false)
    notify(`resources.challenge.helpers.validated`, { type: 'success' })
    redirect('list', 'challenge')
  }

  return (
    <TopToolbar sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
      <Button
        disabled={disabled}
        color="primary"
        onClick={() => navigate(`/challenge/${record.id}/ranking`)}
        label="resources.challenge.actions.ranking">
        <BarChart />
      </Button>
      <Button
        disabled={disabled}
        color="primary"
        onClick={() => navigate(`/challenge/${record.id}/sessions/${record.criteria}`)}
        label="resources.challenge.actions.sessions">
        <Timer />
      </Button>
      <Button
        disabled={disabled}
        color="primary"
        onClick={() => navigate(`/challenge/${record.id}/stats`)}
        label="resources.challenge.actions.stats">
        <QueryStats />
      </Button>
      <Box component="div" style={{ width: 1, flex: 1 }} sx={{ display: { xs: 'none', sm: 'block' } }} />
      {canEditOrFinalizeDraft && (
        <>
          <Button color="primary" onClick={() => setShowValidateDialog(true)} label="resources.challenge.actions.finalizeDraft">
            <Done />
          </Button>
          <Dialog
            open={showValidateDialog}
            onClose={() => setShowValidateDialog(false)}
            aria-labelledby="alert-dialog-block-title"
            aria-describedby="alert-dialog-block-description">
            <DialogTitle id="alert-dialog-block-title">Êtes-vous sûr de vouloir terminer ce brouillon ?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-block-description">
                La finalisation d'un brouillon entraîne la création d'une nouvelle demande auprès de l'équipe de modération. Vous ne pourrez
                plus modifier ce Challenge par la suite.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" label="Je confirme" disabled={loading} onClick={finalizeDraft} />
            </DialogActions>
          </Dialog>
        </>
      )}
      {canValidate && (
        <>
          <Button
            disabled={!canValidate}
            color="primary"
            onClick={() => setShowValidateDialog(true)}
            label="resources.challenge.actions.validate">
            <Done />
          </Button>
          <Dialog
            open={showValidateDialog}
            onClose={() => setShowValidateDialog(false)}
            aria-labelledby="alert-dialog-block-title"
            aria-describedby="alert-dialog-block-description">
            <DialogTitle id="alert-dialog-block-title">Êtes-vous sûr de vouloir valider ce Challenge ?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-block-description">
                La validation d'un Challenge entraîne sa publication. Le Challenge devient réellement effectif et visible dans l'App.
                Veuillez vous assurer que tous les critères sont bien remplis et exempt de fautes.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" label="Je confirme la validation" disabled={loading} onClick={validate} />
            </DialogActions>
          </Dialog>
        </>
      )}
      {(canEditOrFinalizeDraft || profile.isAdmin()) && <EditButton />}
      {canAccessCreate && profile.isAdmin() && <CloneButton />}
      {canAccessDelete && <DeleteButton mutationMode="pessimistic" />}
    </TopToolbar>
  )
}

const ChallengeImageGrid = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record.images && record.images.length > 0) {
    return (
      <Grid container spacing={3}>
        {record.images.map((image, index) => (
          <Grid item xs={12} key={`challenge-image-${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={image.filename} alt={image.filename} width="600" height="300" />
            <div
              style={{
                backgroundColor: image.averageColor,
                width: 40,
                height: 40,
                marginLeft: 20,
                border: 1,
                borderColor: 'black',
                borderStyle: 'solid'
              }}
            />
          </Grid>
        ))}
      </Grid>
    )
  } else {
    return null
  }
}

const ChallengeShow = (props: any) => {
  const translate = useTranslate()
  const profile = useProfile()
  const { canAccess: canAccessEdit } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'team' })
  return (
    <Show title={<Title />} actions={<ChallengeShowActions />}>
      <TabbedShowLayout>
        <Tab label={translate('resources.challenge.tabs.intro')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.fields.name">
                <TextField source="name" />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.fields.description">
                <TextField source="description" />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.labels.url">
                <UrlField source="url" target={'_blank'} />
              </Labeled>
            </Grid>
            {profile.isAdmin() && (
              <Grid item xs={12}>
                <ChallengeCreatedByField />
              </Grid>
            )}
            <Grid item xs={4}>
              <Labeled label="resources.challenge.fields.startDate">
                <ShortDateTimeField source="startDate" />
              </Labeled>
            </Grid>
            <Grid item xs={8}>
              <Labeled label="resources.challenge.fields.endDate">
                <ShortDateTimeField source="endDate" emptyText="(non renseigné)" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label="resources.challenge.labels.type">
                <ChallengeTypeField />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label="resources.challenge.labels.criteria">
                <ChallengeCriteriaField />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label="resources.challenge.fields.sessionDuration">
                <FunctionField
                  render={(record: any) => {
                    return `${record.sessionDuration / 60} min`
                  }}
                />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label="resources.challenge.fields.scope">
                <TranslatedChipField source="scope" />
              </Labeled>
              <CodeField />
            </Grid>
            <Grid item xs={8}>
              <Labeled label="resources.challenge.fields.priority">
                <NumberField source="priority" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label="resources.challenge.fields.onGoing">
                <ChallengeOngoingField source="onGoing" />
              </Labeled>
            </Grid>
            <Grid item xs={8}>
              <Labeled label="resources.challenge.fields.status">
                <ChallengeStatusField source="status" />
              </Labeled>
            </Grid>
            <Grid item xs={12} md={4}>
              <Labeled label="resources.challenge.labels.global">
                <BooleanField source="global" />
              </Labeled>
            </Grid>
            <Grid item xs={12} md={4}>
              <FunctionField
                label="Progression"
                render={(record: any) => {
                  if (record.global) {
                    return (
                      <Labeled label="resources.challenge.fields.progress">
                        <ChallengeGlobalProgress />
                      </Labeled>
                    )
                  } else {
                    return false
                  }
                }}
              />
            </Grid>
          </Grid>
        </Tab>
        <Tab label={translate('resources.challenge.tabs.images')}>
          <Labeled label="resources.challenge.labels.images">
            <ChallengeImageGrid />
          </Labeled>
        </Tab>

        <Tab label={translate('resources.challenge.tabs.prizes')}>
          <ArrayField source="prizes">
            <Datagrid bulkActionButtons={false}>
              <NumberField source="prizeOrder" label="resources.prize.fields.prizeOrder" />
              <NumberField source="team.ranking" label="resources.team.fields.ranking" />
              <PrizeImageIcon source="image" label="resources.challenge.labels.prizes" />
              <PrizeImageField source="image" label="resources.prize.labels.prizes" />
              <FunctionField
                label="Gagné ?"
                render={(record: any) => {
                  if (record.team?.status === Status.Winner) {
                    return <Chip label="Remporté" color="success" onDelete={() => {}} deleteIcon={<Done />} />
                  } else {
                    return <Chip label="Non remporté" color="default" />
                  }
                }}
              />
              <ShortDateTimeField source="updateDate" label="resources.prize.fields.updateDate" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={translate('resources.challenge.tabs.teams')}>
          <ArrayField source="teams" label="resources.team.helpers.teams">
            <Datagrid bulkActionButtons={false}>
              <NumberField source="id" label="resources.team.fields.id" />
              <TextField source="name" />
              <ShortDateField source="creationDate" label="resources.team.fields.creationDate" />
              <ShowButton resource="team" />
              {canAccessEdit && <EditButton />}
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ChallengeShow
