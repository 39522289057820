import React from 'react'
import {
  ArrayField,
  Datagrid,
  Edit,
  Labeled,
  NumberField,
  required,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar
} from 'react-admin'
import { ShortDateField } from '../components/ShortDateTimeField'
import { TeamChallengeLinkField } from '../components/TeamFields'

const TeamEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const TeamEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic">
      <SimpleForm toolbar={<TeamEditToolbar {...props} />} warnWhenUnsavedChanges>
        <Labeled>
          <NumberField source="id" />
        </Labeled>
        <Labeled>
          <ShortDateField source="creationDate" />
        </Labeled>
        <TextInput source="name" fullWidth validate={[required()]} />
        <Labeled>
          <TeamChallengeLinkField source="challenge.name" />
        </Labeled>
        <Labeled>
          <ArrayField source="accounts" label="resources.team.helpers.accounts">
            <Datagrid bulkActionButtons={false}>
              <NumberField source="id" />
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="pseudo" />
              <TextField source="email" />
              <ShowButton resource="account" label="voir" />
            </Datagrid>
          </ArrayField>
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

export default TeamEdit
