import { useTranslate } from 'react-admin'
import { fetchUtils } from 'ra-core'
import type { CreateParams, DataProvider } from 'ra-core'
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request'
import type { ComparisonOperator, QueryFilter, QuerySort } from '@nestjsx/crud-request'
import queryString from 'query-string'

import Api from './api'
import useProfile from './useProfile'

/* psam 01/25, découverte : est basé sur une copie de ra-data-nestjsx-crud/packages/data-provider/src/index.ts
ce qui explique des parts de code à l'utilité incompréhensible ou jamais exploité.
Présence en package.json, mais jamais d'import : "ra-data-nestjsx-crud": "^1.3.2",
Selon les dates Git, l'inspiration remonterait au moins à la version v1.1.5 Dec 23, 2020

psam 01/25 pas compris pourquoi usage non unifié : parfois d'un httpClient(), parfois de Api.xx()
*/

/*
cas des AutocompleteInput :
{ s: "psam" } -> [{ field: 's', operator: '$cont', value: 'psam' }]
cas de ReferenceInput :
{ role: [Role.Advertiser, Role.Admin] }
pas trouvé de cas pour usage du flattenObject, ceci est un exemple :
{ account: { id: 1879 } } -> { "account.id": 1879 } -> [{ field: 'account.id', operator: '$eq', value: 1879 }]
*/
const composeFilter = (paramsFilter: any): QueryFilter[] => {
  //console.log('psam', paramsFilter)
  const flatFilter = fetchUtils.flattenObject(paramsFilter)
  //console.log('psam', flatFilter)
  return Object.entries(flatFilter).map((entry) => {
    const [key, value] = entry
    const splitKey = key.split('||') // pas trouvé de cas
    let field = splitKey[0]
    let operator = splitKey[1] as ComparisonOperator
    if (!operator) {
      if (Array.isArray(value)) operator = CondOperator.IN // psam 01/25 #23 ajout cas des listes
      else if (
        typeof value === 'boolean' ||
        typeof value === 'number' ||
        (typeof value === 'string' && value.match(/^\d+$/))
      ) operator = CondOperator.EQUALS
      else operator = CondOperator.CONTAINS
    }

    if (field.startsWith('_') && field.includes('.')) { // en source d'origine, on ne sait pas à quoi ça sert
      field = field.split(/\.(.+)/)[1]
    }
    return { field, operator, value}
  })
}

/*
{"key":"val"} -> 'key=val'
*/
const composeQueryParams = (queryParams: any = {}): string => queryString.stringify(fetchUtils.flattenObject(queryParams))

const mergeEncodedQueries = (...encodedQueries: string[]): string =>
  encodedQueries
    .filter((e) => e)
    .map((query: string) => query)
    .join('&')

const DataProviderImpl = (apiUrl: string): DataProvider => {
  const profile = useProfile()
  const translate = useTranslate()

  const httpClient = (apiUrl: string, options: any = {}) => {
    options = {
      headers: new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem('token')
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(apiUrl, options)
  }

  return {
    getList: async (resource, params) => {
      let { page, perPage } = params.pagination
      page = page ?? 1
      const { q: queryParams, ...filter } = params.filter || {}
      //console.log('psam', queryParams)
      const encodedQueryParams = composeQueryParams(queryParams)
      const encodedQueryFilter = RequestQueryBuilder.create({filter: composeFilter(filter)})
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort as QuerySort)
        .setOffset((page - 1) * perPage)
        .query()
      const query: string = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
      const url = `${apiUrl}/${resource}?${query}`

      const { json } = await httpClient(url)
      // redefined in order to use pagination
      return {
        data: json.items,
        total: json.meta.totalItems
      }
    },

    getOne: (resource, params) => {
      return Api.get(`${resource}/${params.id}`)
        .json()
        .then((data: any) => ({
          data
        }))
    },

    /* utilisé par ReferenceInput et ReferenceField
    https://github.com/marmelab/react-admin/blob/v4.16.6/docs/DataProviders.md
    'params' est de la forme : { ids: [456] }
    */
    getMany: async (resource, params) => {
      const queryBuilder = RequestQueryBuilder.create()
      /* psam 01/25 #23, page et limit n'ont pas de réelle utilité puisqu'on demande un ou quelques items
      mais les enlever provoque des warnings en backend challenge.service paginate() de 'nestjs-typeorm-paginate'
      avant d'employer des valeurs par défaut :
Query parameter "page" with value "undefined" was resolved as "NaN", please validate your query input! Falling back to default "1".
Query parameter "limit" with value "undefined" was resolved as "NaN", please validate your query input! Falling back to default "10".
      */
      queryBuilder.setPage(1)
      queryBuilder.setLimit(20)
      queryBuilder.setFilter({ field: 'id', operator: CondOperator.IN, value: `${params.ids}` })
      const json: any = await Api.get(`${resource}?${queryBuilder.query()}`).json()
      return { data: json.items }
    },

    /* utilisé par ReferenceManyField
    */
    getManyReference: (resource, params) => {
      let { page, perPage } = params.pagination
      page = page ?? 1
      const { q: queryParams, ...otherFilters } = params.filter || {}
      const filter: QueryFilter[] = composeFilter(otherFilters)

      filter.push({
        field: params.target,
        operator: CondOperator.EQUALS,
        value: params.id
      })
      const encodedQueryParams = composeQueryParams(queryParams)
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter
      })
        .sortBy(params.sort as QuerySort)
        .setPage(page)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query()
      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
      //console.log(query)
      const url = `${apiUrl}/${resource}?${query}`

      // redefined in order to use pagination
      return httpClient(url).then(({ json }) => ({
        data: json.items,
        total: json.meta.totalItems
      }))
    },

    update: async (resource, params) => {
      let data: any
      if (resource === 'challenge') {
        const formData = new FormData()
        for (const [key, value] of Object.entries<any>(params.data)) {
          /// XXX: no other solution?
          if (key === 'images' || key === 'prizes') {
            if (key === 'images' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }
            if (key === 'prizes' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_prizes_required' })
            }
            for (const img of value) {
              if (img?.id) {
                //console.log(`existing ${key}, doing nothing`, img)
              } else {
                formData.append(key, img.rawFile, img.title)
              }
            }
          } else {
            formData.append(key, value)
          }
        }
        data = await Api.patchFormData(`${resource}/${params.id}`, formData).json()
      } else {
        /// XXX
        if (resource === 'account') {
          data = await Api.patch(`${resource}/${params.id}/admin`, params.data).json()
        } else {
          data = await Api.patch(`${resource}/${params.id}`, params.data).json()
        }
      }
      if (data.statusCode >= 400) {
        return Promise.reject({ message: data.message })
      }
      return { data }
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
          })
        )
      ).then((responses) => ({
        data: responses.map(({ json }) => json)
      })),

    create: async (resource: string, params: CreateParams) => {
      let data: any
      if (resource === 'challenge') {
        const formData = new FormData()
        for (const [key, value] of Object.entries<any>(params.data)) {
          /// XXX: no other solution?
          //console.log('psam', key, value)
          if (key === 'images' && value) { // psam 12/23 avec correctif #6, protection en surplus car censé avoir min 1 image
/* psam 12/23 correctif #6, devenu inutile avec ajout de required() en create.tsx
            if (key === 'images' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }
 */
            for (const img of value) {
              formData.append(key, img.rawFile, img.title)
            }
          } else if (key === 'prizes' && value) { // psam 12/23 correctif #6, value undefined si generatePrizes
            /*if (key === 'prizes' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }*/
            for (const img of value) {
              formData.append(key, img.rawFile, img.title)
            }
          } else if (key === 'teams') {
            for (const team of value) { // log() en back le montre en dto sous la forme : [ '{"name":""}' ]
              formData.append('teams[]', JSON.stringify(team))
            }
          } else if (key === 'qrCodeGroups') { // psam 09/24 correctif associé à nestjs #18
            if (value) { // est undefined si aucun choix dans le widget ; à ne pas transmettre
              for (const id of value) { // value est de la forme ["6961","7114"]
                formData.append('qrCodeGroups[]', id)
              }
            }
          } else {
            formData.append(key, value)
          }
        }
        if (params.data.onlyAdminCanCreateTeams === undefined) {
          formData.append('onlyAdminCanCreateTeams', '0')
        }
        if (profile.isAdmin()) {
          data = await Api.postFormData(resource, formData).json()
        } else {
          data = await Api.postFormData(`${resource}/partner`, formData).json()
        }
      } else {
        data = await Api.post(resource, params.data).json()
      }
      if (data.statusCode >= 400) {
        return Promise.reject({ message: data.message })
      }
      return { data }
    },

    delete: (resource, params) => {
      return Api.delete(`${resource}/${params.id}`).then((json: any) => {
        if (json.statusCode >= 400) {
          return Promise.reject({ message: json.message })
        }
        return {
          data: { ...json, id: params.id }
        }
      })
    },

    deleteMany: async (resource, params) => {
      return Promise.all(
        params.ids.map(async (id) => {
          const data: any = await Api.delete(`${resource}/${id}`).json()
          if (data.statusCode >= 400) {
            return Promise.reject({
              message: translate(`resources.errors.${data.message}`)
            })
          }
          return {
            data
          }
        })
      ).then((responses) => {
        return { data: responses.map(({ json }: any) => json) }
      })
    }
  }
}

export default DataProviderImpl
