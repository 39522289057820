import {
  Button,
  Datagrid,
  FilterButton,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin'
import type { ListProps} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { QueryStats } from '@mui/icons-material'

import { DeviceAccountLinkField, OsField } from '../components/DeviceFields'

const deviceFilters = [
  <SearchInput fullWidth source="s" alwaysOn />,
  <SelectInput
    alwaysOn
    source="os"
    choices={[
      { id: 'android', name: 'Android' },
      { id: 'ios', name: 'iOS' }
    ]}
  />
]

const DeviceListActions = (props: any) => {
  const navigate = useNavigate()
  return (
    <TopToolbar>
      <FilterButton />
      <Button color="primary" onClick={() => navigate(`stats`)} label="resources.challenge.actions.stats">
        <QueryStats />
      </Button>
    </TopToolbar>
  )
}

const DeviceList = (props: ListProps) => (
  <List
    {...props}
    filters={deviceFilters}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
    actions={<DeviceListActions />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="brand" />
      <TextField source="model" />
      <OsField source="os" sortable={false} />
      <DeviceAccountLinkField source="account.pseudo" sortable={false} />
      <DeviceAccountLinkField source="account.email" sortable={false} />
      <ShowButton label="Voir" />
    </Datagrid>
  </List>
)

export default DeviceList
